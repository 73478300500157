.ImageBox {
  background-position: 100% 20%;
  background-size: cover;

  min-height: 50vh;

  display: flex;
  align-items: flex-end;
  text-align: flex-end;

  .MiniBar {
    background: var(--sub);
  }

  h2 {
    font-size: var(--fs1);
    color: #fff;
    font-weight: 800;
    text-shadow: 0 0 12px #000a;
  }

  @media (max-width: 1199.98px) {
    min-height: 40vh;
    h2 {
      font-size: var(--fs2);
    }
  }
}
