.TeamBox {
  .TeamMember {
    margin-right: 8px;
    margin-bottom: 8px;
    .inner {
      border-radius: var(--r3);
      background: var(--bgSub);

      h3,
      p {
        margin-bottom: 0;
      }
      img {
        width: 100px;
      }
    }
  }
}
