body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --fs1: 48px;
  --fs2: 30px;
  --fs3: 22px;
  --fs4: 16px;
  --fs5: 12px;
}

span,
p,
b {
  color: var(--text3);
}
a {
  color: var(--blue);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--text2);
}

h1 {
  font-weight: 700;
  font-size: var(--fs1);
  color: var(--text1);
}

h2 {
  font-weight: 700;
  font-size: var(--fs2);
}

h3 {
  font-weight: 700;
  font-size: var(--fs3);
}

p {
  font-size: var(--fs4);
}
