body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

html {
  --r1: 2px;
  --r2: 4px;
  --r3: 8px;
  --r4: 16px;

  --bg: #fefefe;
  --bgSub: #e7e7e7;

  --navbar: #194632dd;

  --black: #000;
  --white: #fff;
  --blue: #0a3fc2;
  --blue_: #fff;
  --red: #a34637;
  --blue_: #fff;
  --green: #33cc34;
  --green_: #fff;
  --yellow: #dbbf08;
  --yellow_: #000;
  --brown: #4e3835;
  --brown_: #fff;

  --text1: #000;
  --text2: #2e2e2e;
  --text3: #4e4e4e;
  --text4: #6e6e6e;

  --main: #26a47a;
  --main_: #fff;

  --main2: #1c7b5b;
  --main2_: #fff;

  // #4E3835
  // #3B6351
  // #A34637

  --sub: #2b332f;
  --subDarker: #0a3fc2;
  --subBlur: #0a3fc2;
  --sub_: #fff;

  // FontAwesome
  --fa-primary-color: var(--main);
  --fa-secondary-color: var(--sub);
  --fa-secondary-opacity: 0.5;

  // Not Colors
  --border: 0.5px solid #eeeded;
  --borderActive: 1px solid var(--text4);
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #000;
    --bgSub: #151515;

    --black: #fff;
    --white: #000;
    --blue: #0a3fc2;
    --blue_: #000;
    --red: #cf1717;
    --blue_: #000;
    --green: #33cc34;
    --green_: #000;
    --yellow: #dbbf08;
    --yellow_: #000;

    --text1: #fff;
    --text2: #e5e5e5;
    --text3: #bbbbbb;
    --text4: #8e8e8e;

    --main: #26a47a;
    --main_: #fff;

    --main2: #1c7b5b;
    --main2_: #fff;

    // #4E3835
    // #3B6351
    // #A34637

    --sub: #2b332f;
    --subDarker: #0a3fc2;
    --subBlur: #0a3fc2;
    --sub_: #fff;

    // FontAwesome
    --fa-primary-color: var(--main);
    --fa-secondary-color: var(--sub);
    --fa-secondary-opacity: 0.5;

    // Not Colors
    --border: 0.5px solid #eeeded;
    --borderActive: 1px solid var(--text4);
  }
}
