body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background: var(--bg);
  .Routes {
    min-height: calc(100vh - 80px);
  }
}

button {
  // background: var(--sub);
  background: transparent;
  border: 2px solid var(--sub);
  color: var(--sub);
  // border: none;
  border-radius: 16px;
  font-size: var(--fs3);
  font-weight: 500;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--sub_);
    background: var(--sub);
    i,
    svg {
      color: var(--sub_);
    }
  }

  &.sub {
    border-color: var(--bgSub);
    &:hover {
      color: var(--text2);
      i,
      svg {
        color: var(--text2);
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: block;
  }
  &.main {
    border-color: var(--main);
    color: var(--main);
    i,
    svg {
      color: var(--main);
    }
    &:hover {
      background: var(--main);
      color: var(--main_);
      i,
      svg {
        color: var(--main_);
      }
    }
  }

  i,
  svg {
    color: var(--sub);
    font-size: var(--fs3);
    margin-left: 8px;
    transition: 0.7s;
  }

  opacity: 0.9;
  transition: 0.4s;

  &:hover {
    background: var(--sub);
    opacity: 1 !important;
    transition: 0.3s;

    i,
    svg {
      transition: 0.3s;
      transform: scale(1.2);
    }
  }
}

.sub {
  border-radius: var(--r3);
  background: var(--bgSub);
  // border-right: 12px solid var(--main);
}

* {
  transition: 0.15s;
}
