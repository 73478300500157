.ContactPage {
  .container {
    // max-width: 1080px;
    .Logo {
      width: 50%;
      img {
        // height: 64px;
      }
    }
  }
}
