.Header {
  .push {
  }
  .bar {
    z-index: 1000;
    height: 52px;
    position: fixed;
    background: var(--navbar);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    top: 0;
    right: 0;
    left: 0;

    .inner {
      height: 52px;
      //   padding: 12px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .Logo {
        width: 120px;
        height: 32px;
        img {
          max-height: 32px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      button {
        padding: 4px 12px;
        color: var(--sub_);
        border-color: var(--sub_);
        font-size: var(--fs4);
        // line-break: no;
        i,
        svg {
          margin-left: 4px;
          font-size: var(--fs4);
          color: var(--sub_);
        }
      }

      .nav {
        a {
          color: var(--brown);
          font-weight: 500;
          padding: 0 12px;
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }
}
