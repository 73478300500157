.ProjectList {
  background: var(--bgSub);
  .ProjectListItem {
    padding: 12px;
    margin-bottom: 12px;
    border-radius: var(--r3);
    background: var(--bg);
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img,
    i,
    svg {
      width: 32px;
      margin: 12px;
      margin-right: 20px;
      font-size: 32px;
      color: var(--main);
    }

    h3 {
      margin-bottom: 0;
    }

    &.sub {
      background: var(--main);
      h3 {
        color: var(--main_);
      }
      p {
        color: var(--main_);
        opacity: 0.8;
      }
      i,
      svg {
        color: var(--main_);
      }
    }
  }
}
