.ContactFormular {
  .ContactFormularInput {
    margin-top: 32px;
    margin-bottom: 32px;

    input {
      background: var(--bgSub);
      border: none;
      border-radius: var(--r2);
      border-left: 4px solid var(--main);
      padding: 8px 12px;
      font-weight: 600;
      width: 100%;
    }

    .requiredLabel {
      color: var(--red);
    }
  }
}
